import React from 'react'

const LoaderPage = () => {
  return (
    <div className="loader">
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
      <div className="dot dot4"></div>
    </div>
  )
}

export default LoaderPage