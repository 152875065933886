import LayoutWeb from "../../components/LayoutWeb";
import CustomerTable from "../../components/table/CustomerTable";
import "../../index.css";

const Home = () => {
  const getFormatDatetime = (dateString: string) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleString();
  };

  return (
    <>
      <LayoutWeb>
        <div className="p-1">
          <h1 className="text-[24px] text-[#3B82F6] font-bold">
            ข้อมูลลูกค้าลงทะเบียนใหม่
          </h1>
          <CustomerTable />
        </div>
      </LayoutWeb>
    </>
  );
};

export default Home;
