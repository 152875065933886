import { FormApi, SubmissionErrors } from "final-form";
import React from "react";
import { Form, Field } from "react-final-form";

const CreateNewCustomerForm: React.FC = () => {
  function handleSubmit(
    values: Record<string, any>,
    form: FormApi<Record<string, any>, any>,
    callback?: (errors?: SubmissionErrors) => void
  ): Promise<void | SubmissionErrors> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Example: Simulate form validation and submission
        const errors: SubmissionErrors = {};
        if (!values.name) {
          errors.name = "Name is required";
        }
        if (!values.email) {
          errors.email = "Email is required";
        }

        if (Object.keys(errors).length > 0) {
          if (callback) callback(errors);
          reject(errors); // Return the errors for handling
        } else {
          console.log("Form submitted successfully:", values);
          form.reset(); // Reset the form after successful submission
          if (callback) callback(); // No errors on success
          resolve(); // Resolve without any value
        }
      }, 1000); // Simulate async submission
    });
  }

  return (
    <>
      <h3>Create New Customer</h3>
      <div>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, values, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="w-full ">
                  <label className="text-lg my-2 font-light flex">
                    ห้างหุ้นส่วน/บริษัท
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="flex flex-col relative pb-5 font-light text-lg">
                    <Field
                      name="companyName"
                      className="w-full h-[40px] px-4 my-2 bg-white rounded-[6px] focus:outline-[#8CC3F5] border-[1px]"
                      type="string"
                      placeholder="บริษัท รวยไรขีด จำกัด"
                      required
                    />
                  </div>
                </div>
                error
              </div>
              <div>
                <label>Name</label>
                <Field
                  name="name"
                  component="input"
                  placeholder="Customer Name"
                />
              </div>
              <div>
                <label>Email</label>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder="Customer Email"
                />
              </div>

              <button type="submit" disabled={submitting || pristine}>
                Submit
              </button>

              <pre>{JSON.stringify(values, null, 2)}</pre>
            </form>
          )}
        />
      </div>
    </>
  );
};

export default CreateNewCustomerForm;
