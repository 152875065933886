import React, { useEffect, useState } from "react";
import { logout } from "../../stores/slices/Auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import imgProfile from "../../assets/profile.png";
import imgNotification from "../../assets/icon/bell.png";

import imgHouse from "../../assets/icon/home-gray.png";
import imgVector from "../../assets/icon/Vector.png";
import imgVectorDown from "../../assets/icon/chevron-down.png";
import { getStorage } from "../../utils/storage";
import "./Navbar.css"; // Import the CSS file

const Navbar: React.FC<{ handleSubMenuToggle: () => void }> = ({
  handleSubMenuToggle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownProfile, setDropdownProfile] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const userInfo = getStorage("userInfo");
    if (userInfo) {
      const user = JSON.parse(userInfo);
      setFullName(user.full_name);
      setEmail(user.email);
    } else {
      console.log("No user info found");
    }
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const toggleMenu = () => {
    setDropdownProfile(!dropdownProfile);
  };

  return (
    <header className=" text-gray-500 p-2 flex justify-between rounded-tl-[30px] border-b-2 px-[25px]">
      <div className="flex items-center">
        {/* <button
          id="toggleSidebarMobile"
          aria-expanded="true"
          aria-controls="sidebar"
          onClick={handleSubMenuToggle}
          // className="p-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <svg
            id="toggleSidebarMobileHamburger"
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button> */}
        <div className="flex items-center mx-4">
          <div className="mr-3">
            <img src={imgHouse} alt="" className="w-5 h-5" />
          </div>
          <div className="mr-3">
            <img src={imgVector} alt="" className="w-2 h-4" />
          </div>
          <h4 className="text-lg font-bold">หน้าหลัก</h4>
        </div>
      </div>
      <div className="flex items-center">
        <div className="mx-2 flex items-center">
          <img src={imgNotification} className="w-6 h-6" alt="" />
        </div>
        <div className="mx-2 flex">
          <div className="mx-2">
            <img src={imgProfile} alt="" className="w-12 h-12" />
          </div>
          <div className="mx-2">
            <div>{fullName}</div>
            <div>{email}</div>
          </div>
          <div className="ml-2 flex items-center">
            <button
              id="toggleMenuProfile"
              aria-expanded="true"
              aria-controls="menuProfile"
            >
              <img src={imgVectorDown} alt="" onClick={toggleMenu} />
            </button>
            <div
              className={`z-50 my-4 text-base list-none bg-current divide-y  rounded shadow  block menu-dropdown-header ${
                dropdownProfile ? "" : "hidden"
              }`}
              id="profile-dropdown"
              data-popper-placement="bottom"
            >
              <ul className="py-1" role="none">
                <li className="h-2 px-1 mb-2">
                  {/* <div className="h-8 border-b-2"></div> */}
                </li>
                <li>
                  <Link
                    to="/"
                    className="block text-center px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-700 keychainify-checked"
                  >
                    Chang Password
                  </Link>
                </li>
                <li>
                  <button
                    className="block px-4 py-2 w-[100%] text-sm text-white hover:bg-gray-100 hover:text-gray-700 keychainify-checked"
                    onClick={handleLogout}
                  >
                    Sign out
                  </button>
                </li>
                <li className="h-2 px-1 mt-2">
                  {/* <div className="h-8 border-t-2"></div> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
