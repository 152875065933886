import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Field, useField } from "react-final-form";

// Define props interface
interface FieldInputWebProps {
  label?: string; // Optional label for the input
  name: string; // Name of the input field
  type?: string; // Type of the input (text, number, etc.)
  placeholder?: string; // Placeholder text
  component?: string; // Component type (input, select, etc.)
  children?: ReactNode | ((props: { input: any; meta: any }) => ReactNode); // Children or render function
  onSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void; // Optional select handler
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void; // Optional change handler
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void; // Optional blur handler
  requiredField?: boolean; // Whether the field is required
  disabledFiled?: boolean; // Whether the field is disabled
  maxLength?: number; // Maximum length for input
  value?: any; // Initial value for the input
  className?: string;
  checked?: boolean;
}

const FieldInputWeb: React.FC<FieldInputWebProps> = ({
  label,
  name,
  type = "text",
  placeholder,
  component,
  children,
  onSelect,
  onChange,
  onBlur,
  requiredField = false,
  disabledFiled = false,
  maxLength,
  value,
  className,
  checked,
}) => {
  const { input, meta } = useField(name); // Get input and meta from react-final-form
  const inputRef = useRef<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >(null)
  const [hasFocused, setHasFocused] = useState(false); // Track if the input has been focused once
  // Check if the field is required and empty, then focus
  useEffect(() => {
    if (requiredField && meta.error && meta.touched && !hasFocused) {
      inputRef.current?.focus(); // Focus the input if there's an error
      setHasFocused(true);
    }
  }, [meta.error, meta.touched, requiredField, hasFocused]);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    // Call the original onChange function if provided
    if (onChange) {
      onChange(e);
    }
    // Call react-final-form's onChange
    input.onChange(e);
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    // Call the original onBlur function if provided
    if (onBlur) {
      onBlur(e);
    }
    // Call react-final-form's onBlur
    input.onBlur(e);
  };

  const allowDigits =
    (numberOfDigits: number) => (value: string | undefined) => {
      if (!value) return value;
      return value.replace(/[^\d]/g, "").slice(0, numberOfDigits);
    };

  return (
    <div className={`w-full ${className ?? ''}`}>
      {label && (
        <label className="text-lg my-2 font-light flex">
          {label}
          {requiredField ? <span className="text-red-500 ml-1">*</span> : null}
        </label>
      )}
      <div className="flex flex-col relative pb-5 font-light text-lg">
        {component === "radio" ? (
          <label className="flex items-center">
            <input
              type="radio"
              {...input} // Use the input from the field context
              onChange={handleChange} // Ensure react-final-form's onChange is called
              checked={checked}
              ref={inputRef as React.LegacyRef<HTMLInputElement>} // Set ref to focus
            />
            <div className="pl-2">
              {typeof children === "function"
                ? children({ input, meta })
                : children}
            </div>
          </label>
        ) : component === "counter" ? (
          <div className="relative">
            <input
              {...input}
              className={`w-full h-[40px] px-4 my-2 text-center text-sm bg-white rounded-[6px] focus:outline-[#8CC3F5] border-[1px] ${className}
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
              type="number"
              placeholder={placeholder}
              min={0}
              ref={inputRef as React.LegacyRef<HTMLInputElement>} // Set ref to focus
            />
          </div>
        ) : component === "textarea" ? (
          <textarea
            {...input}
            className={`w-full h-[80px] px-3 py-2 my-2 bg-white rounded-[15px] focus:outline-[#8CC3F5] border-[1px] ${className}
              ${
                meta.error && meta.touched ? "border-[1px] border-rose-500" : ""
              }`}
            placeholder={placeholder}
            disabled={disabledFiled}
          />
        ) : component === "select" ? (
          <select
            {...input} // Use input instead of select
            className={`w-full h-[40px] px-2 my-2 font-light bg-white rounded-[6px] focus:outline-[#8CC3F5] border-[1px] ${className}
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
            onSelect={onSelect}
            onChange={handleChange} // Call custom handleChange
            onBlur={onBlur} // Call custom handleBlur
            disabled={disabledFiled}
            ref={inputRef as React.LegacyRef<HTMLSelectElement>} // Set ref to focus
          >
            {typeof children === "function"
              ? children({ input, meta })
              : children}
          </select>
        ) : component === "dateTime" ? (
          <div className="relative">
            <input
              {...input}
              className={`w-full h-[40px] px-4 my-2 text-center bg-white rounded-[6px] focus:outline-[#8CC3F5] border-[1px] ${className}
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
              type="datetime-local"
              placeholder={placeholder}
              min={0}
              ref={inputRef as React.LegacyRef<HTMLInputElement>} // Set ref to focus
            />
          </div>
        ) : maxLength ? (
          <Field
            className={`w-full h-[40px] px-4 my-2 bg-white rounded-[6px] focus:outline-[#8CC3F5] border-[1px] ${className}
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
            name={name}
            parse={allowDigits(maxLength)}
            component="input"
            onBlur={handleBlur}
            ref={inputRef as React.LegacyRef<HTMLInputElement>} // Set ref to focus
          />
        ) : (
          <input
            {...input}
            className={`w-full h-[40px] px-4 my-2 bg-white rounded-[6px] focus:outline-[#8CC3F5] border-[1px] ${className}
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }${className}`}
            type={type}
            placeholder={placeholder}
            disabled={disabledFiled}
            onChange={handleChange} // Use custom handleChange
            onBlur={handleBlur} // Use custom handleBlur
            ref={inputRef as React.LegacyRef<HTMLInputElement>} // Set ref to focus
          />
        )}
        {meta.error && meta.touched && (
          <span className="text-xs text-red-500 pt-1 absolute bottom-1">
            {meta.error}
          </span>
        )}
      </div>
    </div>
  );
};

export default FieldInputWeb;
