import React, { useState } from 'react'
import LayoutWeb from '../../components/LayoutWeb'
import LoaderPage from '../../components/LoaderPage'
import CreateNewCustomerForm from '../../components/form/CreateNewCustomerForm';

const Create: React.FC = () => {
    const [loadingPage, setLoadingPage] = useState(false);
  return (
    <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
          <CreateNewCustomerForm/>
          </>
        )}
    </LayoutWeb>
  )
}

export default Create
