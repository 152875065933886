import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./DropdownTable.css";

interface DropdownMenuProps {
  rowId: number;
  onDelete: (id: number) => void;
}

const DropdownTable: React.FC<DropdownMenuProps> = ({ rowId, onDelete }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleDelete = async () => {
    console.log(rowId);
    
    await onDelete(rowId);
    setIsOpen(false); // Close the dropdown after deletion
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const adjustDropdownPosition = () => {
    const dropdown = dropdownRef.current;
    const button = buttonRef.current;

    if (dropdown && button) {
      const buttonRect = button.getBoundingClientRect();
      const dropdownHeight = dropdown.offsetHeight;
      const dropdownWidth = dropdown.offsetWidth;

      // Check if the dropdown would overflow the screen
      const shouldMoveUp = buttonRect.bottom + dropdownHeight > window.innerHeight;
      const shouldMoveLeft = buttonRect.left + dropdownWidth > window.innerWidth;

      dropdown.style.top = shouldMoveUp ? `${buttonRect.top - dropdownHeight}px` : `${buttonRect.bottom}px`;
      dropdown.style.left = shouldMoveLeft ? `${buttonRect.left - dropdownWidth}px` : `${buttonRect.left}px`;
    }
  };

  useEffect(() => {
    if (isOpen) {
      adjustDropdownPosition();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        id="toggleMenuTable"
        onClick={toggleDropdown}
      >
        <svg
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.32734 15.3461C6.45587 15.3461 7.37074 14.4028 7.37074 13.2392C7.37074 12.0756 6.45587 11.1323 5.32734 11.1323C4.1988 11.1323 3.28394 12.0756 3.28394 13.2392C3.28394 14.4028 4.1988 15.3461 5.32734 15.3461Z"
            fill="#000000"
          />
          <path
            d="M12.4792 15.3461C13.6077 15.3461 14.5226 14.4028 14.5226 13.2392C14.5226 12.0756 13.6077 11.1323 12.4792 11.1323C11.3507 11.1323 10.4358 12.0756 10.4358 13.2392C10.4358 14.4028 11.3507 15.3461 12.4792 15.3461Z"
            fill="#000000"
          />
          <path
            d="M19.631 15.3461C20.7596 15.3461 21.6744 14.4028 21.6744 13.2392C21.6744 12.0756 20.7596 11.1323 19.631 11.1323C18.5025 11.1323 17.5876 12.0756 17.5876 13.2392C17.5876 14.4028 18.5025 15.3461 19.631 15.3461Z"
            fill="#000000"
          />
        </svg>
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="z-50 my-4 px-2 text-base list-none bg-current divide-y shadow block menu-dropdown-header-table rounded-md bg-white"
          style={{ position: "fixed" }} // Make sure dropdown is positioned relative to the viewport
        >
          <ul className="py-1">
            <li>
              <Link
                to={`/new-customer/${rowId}`}
                className="flex items-center w-full text-left mx-1 py-2 hover:bg-[#3758F9] hover:rounded-md hover:text-white"
              >
                                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg0"
                  className="mx-2"
                >
                  <path
                    d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                    stroke="currentColor"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                    stroke="currentColor"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="mx-2">กรอกข้อมูลเพิ่มเติม</div>
              </Link>
            </li>
            <li>
              <button
                onClick={handleDelete}
                className="flex items-center w-full text-left px-1 py-2 hover:bg-red-200 hover:rounded-md text-red-600"
              >
                                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2"
                >
                  <path
                    d="M3 6H21"
                    stroke="currentColor"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 6V20C19 21 18 22 17 22H7C6 22 5 21 5 20V6"
                    stroke="currentColor"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 6V4C8 3 9 2 10 2H14C15 2 16 3 16 4V6"
                    stroke="currentColor"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 11V17"
                    stroke="currentColor"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 11V17"
                    stroke="currentColor"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="mx-2">ลบ</div>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownTable;
