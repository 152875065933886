import React from "react";
import LogoBanner from "../../assets/LogoBanner.png";
import LogoFacebook from "../../assets/logo/facebook.svg";
import LogoLine from "../../assets/logo/line.svg";
import LogoYoutube from "../../assets/logo/youtube.svg";
import IconMail from "../../assets/icons8-mail-48.png";
import IconPhone from "../../assets/icons8-phone-48.png";
import Ellipse2730 from "../../assets/footer/Ellipse_2730.png";
import Ellipse2731 from "../../assets/footer/Ellipse_2731.png";
import Ellipse2732 from "../../assets/footer/Ellipse_2732.png";

const FooterRegister: React.FC = () => {
  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  return (
    <div className="relative flex flex-wrap text-[#FFF] pb-4 pl-5 md:pl-0">
      <div className="md:basis-1/2 lg:basis-1/4 flex items-center justify-center">
        <img
          src={LogoBanner}
          alt="LogoBanner"
          className="w-full md:w-[20rem] z-10"
        />
      </div>
      <div className="md:basis-1/2 lg:basis-1/4 text-left">
        <div className="md:my-3 lg:my-7 hidden lg:block text-lg">ที่อยู่</div>
        <div className="md:my-3 lg:my-7">
          389/40 หมู่ที่ 2 ตำบลสันผีเสื้อ อำเภอเมืองเชียงใหม่ จังหวัดเชียงใหม่
          รหัสไปรษณีย์ 50300
        </div>
        <div className="flex md:justify-start my-5">
          <a
            href="mailto:chokumnuaichiangmai@gmail.com"
            className="flex items-center"
          >
            <img src={IconMail} className="w-5" alt="Mail Icon" />
            <span className="ml-2">chokumnuaichiangmai@gmail.com</span>
          </a>
        </div>
      </div>
      <div className="md:basis-1/2 lg:basis-1/4 text-center flex flex-col items-center">
        <div className="my-2 md:my-7 text-lg -ml-3">ข้อมูลการติดต่อสอบถาม</div>
        <div className="flex items-center justify-center md:justify-start">
          <a href="tel:0620264554" className="flex items-center">
            <img src={IconPhone} className="w-5" alt="Mail Phone" />
            <span className="ml-2">062-0264554 </span>
          </a>
          <div className="ml-2">สำนักงาน</div>
        </div>
        <div className="flex items-center justify-center md:justify-start">
          <a href="tel:0620265454" className="flex items-center">
            <img src={IconPhone} className="w-5" alt="Mail Phone" />
            <div className="ml-2">062-0262424</div>
          </a>
          <div className="ml-2">สำนักงาน</div>
        </div>
        <div className="flex items-center justify-center md:justify-start">
          <a href="tel:0634644647" className="flex items-center">
            <img src={IconPhone} className="w-5" alt="Mail Phone" />
            <div className="ml-2">063-4614647</div>
          </a>
          <div className="ml-2">คุณรัชพล</div>
        </div>
        <div className="flex items-center justify-center md:justify-start">
          <a href="tel:0816949845" className="flex items-center">
            <img src={IconPhone} className="w-5" alt="Mail Phone" />
            <div className="ml-2">081-6949845</div>
          </a>
          <div className="ml-2">คุณอำพล</div>
        </div>
        <div className="flex items-center justify-center md:justify-start">
          <a href="tel:0815959004" className="flex items-center">
            <img src={IconPhone} className="w-5" alt="Mail Phone" />
            <div className="ml-2">081-5959004</div>
          </a>
          <div className="ml-2">คุณดนิตา</div>
        </div>
      </div>
      <div className="md:basis-1/2 lg:basis-1/4">
        <div className="my-2 md:my-7 text-lg">ช่องทางการติดต่อ</div>
        <div className="flex flex-row justify-normal my-2 md:my-7">
          <div className="m-1 w-10">
            <a
              href="https://www.facebook.com/newchokumnuaichiangmai?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={LogoFacebook}
                alt="icon-notice"
                className="w-14 md:w-16 lg:w-28 mx-auto"
              />
            </a>
          </div>
          <div className="m-1 w-10">
            <a
              href="http://line.me/ti/p/~@new_cm"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={LogoLine}
                alt="icon-notice"
                className="w-14 md:w-16 lg:w-28 mx-auto"
              />
            </a>
          </div>
          <div className="m-1 w-10">
            <a
              href="https://youtube.com/channel/UCCJK42_LlkLByuXfOSdUgZw?si=ZIBXXciLVckCwA43"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={LogoYoutube}
                alt="icon-notice"
                className="w-14 md:w-16 lg:w-28 mx-auto"
              />
            </a>
          </div>
        </div>
        <div className="mt-4">
          {" "}
          © {currentYear} NEW CHOKUMNUAI COMPANY LIMITED{" "}
        </div>
      </div>

      <img
        src={Ellipse2731}
        alt="Floating-image"
        className="absolute bottom-0 left-0"
      />
      <img
        src={Ellipse2730}
        alt="icon-notice"
        className="absolute right-[1%] top-2 w-[82px]"
      />
      <img
        src={Ellipse2732}
        alt="icon-notice"
        className="absolute bottom-[40%] lg:bottom-0 right-[26%] w-[80px]"
      />
    </div>
  );
};

export default FooterRegister;
