import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../../services/authApi";
import { setUserInfo } from "../../stores/slices/Auth";
import LoginForm from "../../components/form/LoginForm"; // Import LoginForm component
import "../../index.css";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state: any) => state.auth);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    const fromInput = e.currentTarget.elements;
    AuthService.login(fromInput.username.value, fromInput.password.value).then(
      async (res) => {
        if (res.data !== null) {
          await dispatch(
            setUserInfo({
              accessToken: res.data.access_token,
              user: res.data.user_info,
            })
          );
          navigate("/", { replace: true });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(resMessage);
      }
    );
  };

  useEffect(() => {
    if (token !== "") {
      navigate("/", { replace: true });
    }
  }, [token]);

  return (
    <div
      className="bg-gray-100 flex flex-col items-center justify-center min-h-screen md:py-4"
      style={{
        backgroundImage: `url(${require("../../assets/background/bg_login.jpg")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <LoginForm handleLogin={handleLogin} />
    </div>
  );
};

export default LoginPage;
