import apiClient from "./apiClient";
import { GetNewCustomerFilter } from "../types/customer";
import { getStorage } from "../utils/storage";

const getProvices = () => {
  return apiClient
    .get("masters/provices")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const getAmphure = (provicesId: string) => {
  return apiClient
    .get(`masters/provices/${provicesId}/amphures`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const getTambon = (proviceId: string, amphureid: string) => {
  return apiClient
    .get(`masters/provices/${proviceId}/amphures/${amphureid}/tambons`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const postcodeAddresses = (postcode: string) => {
  return apiClient
    .get(`masters/postcode-addresses?postcode=${postcode}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const getNewCustomers = (payload: GetNewCustomerFilter) => {
  let filters = "?";
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      if (
        payload[key as keyof GetNewCustomerFilter] !== undefined &&
        payload[key as keyof GetNewCustomerFilter] !== null &&
        payload[key as keyof GetNewCustomerFilter] !== ""
      ) {
        filters += `${encodeURIComponent(key)}=${encodeURIComponent(
          payload[key as keyof GetNewCustomerFilter]
        )}&`;
      }
    }
  }
  filters = filters.slice(0, -1);
  const token = getStorage("token");
  return apiClient
    .get(`customers${filters}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const deleteNewCustomerById = async (id: string) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.delete(`customers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response.data;
  } catch (e) {
    console.error("error", e);
    return e;
  }
};

const getNewCustomerByid = async (id: string) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.get(`customers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response.data;
  } catch (e) {
    console.error("error", e);
    return e;
  }
};

const getCategoryTypes = () => {
  return apiClient
    .get("masters/category-types")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const getBusinessTypes = (cetegoryId: string) => {
  return apiClient
    .get(`masters/category-types/${cetegoryId}/business-types`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const addNewCustomer = (payload: any) => {
  return apiClient
    .post(`customers`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const updateCustomer = (id: string,payload: any) => {
  const token = getStorage("token");
  return apiClient
    .put(`customers/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const newCustomerRegisterServices = {
  getProvices,
  getAmphure,
  getTambon,
  postcodeAddresses,
  getNewCustomers,
  getCategoryTypes,
  getBusinessTypes,
  addNewCustomer,
  updateCustomer,
  getNewCustomerByid,
  deleteNewCustomerById,
};
export default newCustomerRegisterServices;
