import { getStorage, removeStorage, setStorage } from "../utils/storage";
import apiClient from "./apiClient";

const register = (username: string, password: string) => {
  return apiClient
    .post("signup", {
      username,
      password,
    })
    .catch((e) => {
      return Promise.reject(e.response ? e.response.data : e.message);
    });
};

const login = (username: string, password: string) => {
  return apiClient
    .post("login", {
      username,
      password,
    })
    .then((response) => {
      if (response.status === 200) {
        setStorage("token", response.data.data.access_token);
        setStorage("refreshToken", response.data.data.refresh_token);
        setStorage("userInfo", JSON.stringify(response.data.data.user_info));
      }
      if (response.status === 401) {
        // Handle Refresh Token
      }
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(e.response ? e.response.data : e.message);
    });
};

const refreshToken = () => {
  const refreshToken = getStorage("refreshToken");
};

const logout = () => {
  removeStorage("token");
  removeStorage("refreshToken");
  removeStorage("userInfo");
  return apiClient
    .post("logout")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(e.response ? e.response.data : e.message);
    });
};

const getUserInfo = () => {
  const userInfo = getStorage("userInfo");
  return userInfo ? JSON.parse(userInfo) : null;
};

const getToken = () => {
  const token = getStorage("token");
  return token ? token : null;
};

const AuthService = {
  register,
  login,
  logout,
  refreshToken,
  getUserInfo,
  getToken,
};

export default AuthService;
