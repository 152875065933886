import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../../services/authApi";
import { setUserInfo } from "../../stores/slices/Auth";
import ForgotPasswordForm from "../../components/form/ForgotPasswordForm"; // Import LoginForm component
import "../../index.css";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state: any) => state.auth);

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();
    // const fromInput = e.currentTarget.elements;
    // AuthService.login(fromInput.username.value, fromInput.password.value).then(
    //   async (res) => {
    //     console.log(res);
    //     if (res.data !== null) {
    //       await dispatch(
    //         setUserInfo({
    //           accessToken: res.data.access_token,
    //           user: res.data.user_info,
    //         })
    //       );
    //       navigate("/", { replace: true });
    //     }
    //   },
    //   (error) => {
    //     const resMessage =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();

    //     console.log(resMessage);
    //   }
    // );
  };

  return (
    <div
      className="bg-gray-100 flex flex-col items-center justify-center min-h-screen md:py-4"
      style={{
        backgroundImage: `url(${require("../../assets/background/bg_login.jpg")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ForgotPasswordForm handleForgotPassword={handleForgotPassword} />
    </div>
  );
};

export default LoginPage;
