import LayoutWeb from "../../components/LayoutWeb";
import CustomerTable from "../../components/table/CustomerTable";
import "../../index.css";

const Home = () => {
  return (
    <>
      <LayoutWeb>
        <h1 className="text-3xl font-bold">New Chokumnuai</h1>
        <CustomerTable />
      </LayoutWeb>
    </>
  );
};

export default Home;
