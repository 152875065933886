import LayoutWeb from "../components/LayoutWeb";
import "../index.css";
import TotalNewRegister from "../components/dashboard/TotalNewRegister"

const Home = () => {
  return (
    <>
      <LayoutWeb>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <TotalNewRegister/>
        </div>
      </LayoutWeb>
    </>
  );
};

export default Home;
