import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../index.css"; // Import CSS if needed
import logo from "../../assets/logo/logo_login.png"; // Adjust the path to your logo

const LoginForm = ({ handleLogin }: { handleLogin: (e: any) => void }) => {
  return (
    <div className="px-4">
      <form
        onSubmit={handleLogin}
        className="bg-white rounded-3xl shadow-md flex flex-col h-[500px] w-full md:w-[448px] md:mx-4 items-center transition duration-1000 ease-out"
      >
        <div className="p-6">
          <div className="flex justify-center my-2">
            <img src={logo} alt="logo" className=" transform  w-32 h-32" />
          </div>
          <input
            type="text"
            className="rounded-lg px-2 py-3 my-2 w-[100%] sm:w-full border-[1px] border-gray-200 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
            placeholder="อีเมล"
            id="username"
            name="username"
            required
          ></input>
          <input
            type="password"
            className="rounded-lg px-2 py-3 my-2 w-[100%] md:w-full border-[1px] border-gray-200 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
            placeholder="รหัสผ่าน"
            id="password"
            name="password"
            required
          ></input>
          <button
            type="submit"
            className="rounded-lg m-1 my-2 text-white bg-blue-600 w-[100%] px-4 py-3 shadow-md hover:text-blue-400 hover:bg-white transition duration-200 ease-in"
          >
            เข้าสู่ระบบ
          </button>
        </div>
        <div className="inline-block border-[1px] justify-center w-[80%] border-gray-200 border-solid"></div>
        <Link to="/forgot-password" className="text-gray-400 mt-4 text-sm">
          ลืมรหัสผ่าน ?
        </Link>
      </form>
    </div>
  );
};

export default LoginForm;
