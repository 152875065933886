import React, { useState } from "react";
import "../../index.css"; // Import CSS if needed
import logo from "../../assets/logo/logo_login.png"; // Adjust the path to your logo

const ForgotPasswordForm = ({
  handleForgotPassword,
}: {
  handleForgotPassword: (e: any) => void;
}) => {
  return (
    <div className="px-4">
      <form
        onSubmit={handleForgotPassword}
        className="bg-white rounded-3xl shadow-md flex flex-col h-[500px] w-full md:w-[448px] md:mx-4 items-center transition duration-1000 ease-out"
      >
        <div className="p-6 h-[100%] flex flex-col justify-center">
          <div className="flex justify-center my-8">
            <img src={logo} alt="logo" className=" transform  w-32 h-32" />
          </div>
          <div className="flex justify-center text-xl font-bold my-2">
            ลืมรหัสผ่าน
          </div>
          <div className="text-gray-400 my-3">
            กรุณากรอกอีเมลของคุณระบบจะทำการส่งลิ้งค์เพื่อตั้งรหัสผ่านใหม่
            ไปยังอีเมลของคุณ
          </div>
          <input
            type="text"
            className="rounded-lg px-2 py-3 my-2 w-[100%] sm:w-full border-[1px] border-gray-200 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
            placeholder="อีเมล"
            id="username"
            name="username"
            required
          ></input>
          <button
            type="submit"
            className="rounded-lg m-1 my-2 text-white bg-blue-600 w-[100%] px-4 py-3 shadow-md hover:text-blue-400 hover:bg-white transition duration-200 ease-in"
          >
            ยืนยัน
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
